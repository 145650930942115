import React, { useState, useEffect, useCallback } from "react";
import Item from "../Item";
import { stepStyle } from "../../data/step-style";
import StepStyled from "../StepStyled";

export interface IItem {
  key: string;
  name: string;
  link: string;
  image: string;
  disable: boolean;
}

export interface ISection {
  question: string;
  items: IItem[];
}

const blurOnClick = (target: any) => {
  target.blur();
  target.parentElement.blur();
  target.parentElement.parentElement.blur();
};

const StepSection = ({
  getSelectedKey,
  step,
  data: { question, items },
  currentStep,
  allowMultipleSelection,
  getUnselectedKey,
  selectedKeys,
}: {
  getSelectedKey: Function;
  step: number;
  data: ISection;
  currentStep: number;
  allowMultipleSelection?: boolean;
  getUnselectedKey: Function;
  selectedKeys: string[][];
}) => {
  const [selectedItems, setSelectedItems] = useState([-1]);
  let style = stepStyle[step - 1];

  const updateSelection = (items: IItem[], selectedKeys: string[][] = []) => {
    const last = selectedKeys.length - 1;

    if (last < step) {
      setSelectedItems([-1]);
      return;
    }

    selectedKeys.forEach((keys, mStep) => {
      if (step !== mStep) return;

      let selected = [-1];

      items.forEach(({ key }, i) => {
        keys.forEach((mKey: string) => {
          if (key === mKey) selected.push(i);
        });
      });

      setSelectedItems(selected);
    });
  };

  const selectItem = (
    event: any,
    disable: any,
    i: any,
    key: any,
    step: any,
    isClick: boolean = true
  ) => {
    var code = null;
    if (event) {
      code = event.keyCode || event.which;
      blurOnClick(event.target);
    }

    if ((code && code === 13) || isClick) {
      event && event.preventDefault();

      if (disable) return;

      const canUnselectItem =
        selectedItems.find((s) => s === i) !== undefined &&
        selectedItems.filter((s) => s !== i && s !== -1).length > 0;

      if (canUnselectItem) {
        getUnselectedKey(step, key);
      } else {
        getSelectedKey(key, step, allowMultipleSelection);
      }
    }
  };

  const updateSelectionCb = useCallback(updateSelection, []);

  useEffect(() => {
    updateSelectionCb(items, selectedKeys);
  }, [items, selectedKeys, updateSelectionCb]);

  const autoSelectionSingleItem = (items: IItem[]) => {
    if (items.length === 1) {
      const { disable, key } = items[0];
      selectItem(null, disable, 0, key, step);
    }
  };

  const autoSelectionSingleItemCb = useCallback(autoSelectionSingleItem, []);
  const stepHeaderRef: { [id: string]: React.RefObject<HTMLHeadingElement> } = {
    currentStep: React.createRef<HTMLHeadingElement>(),
  };

  useEffect(() => {
    if (currentStep === step && items.length === 1) {
      autoSelectionSingleItemCb(items);
    }
  }, [autoSelectionSingleItemCb, items, currentStep, step]);

  useEffect(() => {
    if (stepHeaderRef[step]) {
      stepHeaderRef[step].current?.focus();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section
      id={step.toString()}
      className={`mmmAceMicrosite-step mmmAceMicrosite-step--${style}`}
    >
      <h2
        className="mmmAceMicrosite-step-header"
        ref={stepHeaderRef[step]}
        tabIndex={-1}
      >
        {question}
      </h2>

      {allowMultipleSelection ? <p>Select all that apply</p> : ""}

      <ul className="mmmAceMicrosite-step-items">
        {items.map(({ key, name, disable }, i) => (
          <Item
            key={i}
            index={i}
            tabIndex={0}
            isDisabled={disable}
            isCurrentStep={currentStep === step}
            isSelected={selectedItems.find((s) => s === i) === i}
            onClick={(e: any) => selectItem(e, disable, i, key, step)}
            onKeyPress={(e: any) => selectItem(e, disable, i, key, step)}
          >
            <StepStyled name={name} style={style} id={key} key={key} />
          </Item>
        ))}
      </ul>
    </section>
  );
};

export default StepSection;
