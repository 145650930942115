import React from "react";
import { getImageName } from "../../scripts/utils";

const ImageStepItem = ({
  itemKey,
  name,
}: {
  itemKey: string;
  name: string;
}) => {
  return (
    <div id={itemKey} className="mmmAceMicrosite-image-selector">
      <div>
        <img
          srcSet={`${process.env.MEDIA_URL}/media/images/images/${getImageName(
            name
          )}.jpg 1x,
                    ${process.env.MEDIA_URL}/media/images/images/${getImageName(
            name
          )}@2x.jpg 2x,
                    ${process.env.MEDIA_URL}/media/images/images/${getImageName(
            name
          )}@3x.jpg 3x`}
          src={`${process.env.MEDIA_URL}/media/images/images/${getImageName(
            name
          )}.jpg `}
          alt=""
        />
      </div>
      <p>{name}</p>
    </div>
  );
};

export default ImageStepItem;
