import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

const getImageName = (name: string) =>
  name.replace(/[^a-z]/gi, "").toLocaleLowerCase();

const scrollTo = (
  step: number,
  ignore: Boolean,
  getById: Function = document.getElementById.bind(document)
) => {
  if (ignore) return;

  const elementToScroll = getById(step === 1 ? "0" : step.toString());

  elementToScroll &&
    elementToScroll.scrollIntoView &&
    elementToScroll.scrollIntoView({
      behavior: "smooth",
    });

  return elementToScroll;
};

export { getImageName, scrollTo };
