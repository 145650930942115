import React from "react";

const RoundStepItem = ({
  itemKey,
  name,
}: {
  itemKey: string;
  name: string;
}) => {
  return (
    <div id={itemKey} className="mmmAceMicrosite-round-selector">
      <p>{name}</p>
    </div>
  );
};

export default RoundStepItem;
