import React, { useState, useEffect, useCallback } from "react";
import Products from "../Products";
import StepSection from "../StepSection";
import { scrollTo } from "./../../scripts/utils";
import {
  setHistory,
  clearHistory,
  reloadData,
  haveHistory,
} from "./../../scripts/History";

interface IState {
  step: number;
  data: any;
  selectedKeys: string[][];
}

const StepManager = () => {
  const [state, setState] = useState<IState>({
    step: 0,
    data: [],
    selectedKeys: [],
  });

  const getSelectedKey = (
    key: string,
    step: number,
    allowMultipleSelection: boolean = false
  ) => {
    setState(reloadData(setHistory({ step, key, allowMultipleSelection })));
  };

  const getUnselectedKey = (step: number, key: string) => {
    setState(reloadData(clearHistory({ step, key })));
  };

  const onLoad = () => {
    if (haveHistory()) {
      setState(reloadData());
    } else {
      getSelectedKey("", 1);
    }
  };

  const onLoadCallback = useCallback(onLoad, []);

  useEffect(() => {
    onLoadCallback();
  }, [onLoadCallback]);

  useEffect(() => {
    const ignoreScroll = () => {
      const noUndefinedArr = state.data.filter((e: any) => e !== undefined);
      const lastIndex = noUndefinedArr[noUndefinedArr.length - 2];

      if (lastIndex) return lastIndex.allowMultipleSelection;

      return false;
    };

    scrollTo(state.step, ignoreScroll());
  }, [state]);

  const startOver = () => {
    scrollTo(0, false);

    setTimeout(() => {
      getUnselectedKey(0, "");
    }, 700);
  };

  return (
    <>
      {(() => {
        const { data } = state;
        return data.map((_: any, index: number) => {
          if (index >= 1 && index <= 9) {
            return (
              <StepSection
                key={index}
                step={index}
                currentStep={state.step}
                getSelectedKey={getSelectedKey}
                getUnselectedKey={getUnselectedKey}
                allowMultipleSelection={_.allowMultipleSelection}
                selectedKeys={state.selectedKeys}
                data={data[index]}
              />
            );
          } else if (index === 99) {
            return (
              <Products
                key={index}
                step={index}
                startOver={startOver}
                data={data[index]}
              />
            );
          } else {
            return "";
          }
        });
      })()}
    </>
  );
};

export default StepManager;
