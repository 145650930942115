import React from "react";
import { getImageName } from "../../scripts/utils";

const IconStepItem = ({ itemKey, name }: { itemKey: string; name: string }) => {
  return (
    <button className="mmmAceMicrosite-icon-selector--button">
      <div id={itemKey} className="mmmAceMicrosite-icon-selector">
        <img
          src={`${process.env.MEDIA_URL}/media/images/icons/${getImageName(
            name
          )}.svg`}
          alt={`${name} icon`}
        />
      </div>
      <p>{name}</p>
    </button>
  );
};

export default IconStepItem;
