import React from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import StepManager from "./components/StepManager";
import HeroImage from "./components/HeroImage";

//changed from components/Map
import "leaflet/dist/leaflet.css";

const App = () => (
  <React.StrictMode>
    <div className="App">
      <Header />
      <HeroImage />
      <div role="main" id="main">
        <StepManager />
      </div>
      <Footer />
    </div>
  </React.StrictMode>
);

export default App;
