const styles = {
  round: "round",
  icon: "icon",
  image: "image",
};

const stepStyle = [
  styles.icon,
  styles.image,
  styles.icon,
  styles.icon,
  styles.icon,
  styles.icon,
  styles.icon,
  styles.icon,
  styles.icon,
];

export { stepStyle, styles };
