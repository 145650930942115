import React from "react";
import data from "./../../data/page";

const Footer = () => {
  const { footer } = data;

  return (
    <footer role="contentinfo">
      <div className="mmmAceMicrosite-footer">
        <p id="mmmAceMicrosite-disclaimer">{footer.disclaimer}</p>
        <div className="mmmAceMicrosite-copyright">
          <img alt="3m logo" src={footer.mmmLogo} />
          <p id="mmmAceMicrosite-copy">{footer.copy}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
