const currentYear = new Date().getFullYear();

const page = {
  header: {
    mmmLogo: "./media/images/logo-3-m.svg",
    walmartLogo: "./media/images/logo-walmart.svg",
    walmartDesktopLogo: "./media/images/walmart-logo@3x.png",
    walmartLink: "https://www.walmart.com/",
  },
  footer: {
    disclaimer:
      "*All product and company names are trademarks™ or registered® trademarks of their respective holders. Use of them does not imply any affiliation with or endorsement by them.",
    copy: ` © 3M ${currentYear}. All Rights Reserved.`,
    mmmLogo: "./media/images/logo-3-m-red.svg",
  },
};

export default page;
