import React from "react";
import Image from "../Image";

export interface IItem {
  key: string;
  name: string;
  link: string;
  image: string;
}

export interface ISection {
  question: string;
  items: IItem[];
}

const Products = ({
  startOver,
  step,
  data: { question, items },
}: {
  startOver: Function;
  step: number;
  data: ISection;
}) => (
  <section
    id={step.toString()}
    className="mmmAceMicrosite-step mmmAceMicrosite-products"
  >
    <h2
      className={`mmmAceMicrosite-step-header ${
        items.length <= 0 ? "no-products-found" : ""
      }`}
    >
      {question}
    </h2>
    <ul className="mmmAceMicrosite-products_container">
      {items.map(({ name, image, link }, i) => (
        <li key={i} className="mmmAceMicrosite-card">
          <a href={link}>
            <Image
              src={image}
              name={name}
              className="mmmAceMicrosite-product_image"
            />
            <div className="mmmAceMicrosite-product_name">
              <h2>{name}</h2>
            </div>
            <p className="mmmAceMicrosite-button-selector">Buy this Product</p>
          </a>
        </li>
      ))}
    </ul>
    <button
      onClick={() => startOver()}
      className="mmmAceMicrosite-restart-selector"
    >
      Start Over
    </button>
  </section>
);

export default Products;
