import React from "react";

const Item = (props: any) => {
  const {
    children,
    className,
    isCurrentStep,
    isDisabled,
    isSelected,
    ...params
  }: {
    children: Element | Element[];
    className: string;
    isCurrentStep: boolean;
    isDisabled: boolean;
    isSelected: boolean;
  } = props;

  return (
    <li
      {...params}
      className={`mmmAceMicrosite-item ${className ? className : ""}
                ${
                  isDisabled
                    ? "mmmAceMicrosite-item-disabled"
                    : isSelected
                    ? "mmmAceMicrosite-is-selected"
                    : ""
                }`}
    >
      {children}
    </li>
  );
};

export default Item;
