import React, { useEffect, useState } from "react";

const Image = ({
  src,
  name,
  className,
}: {
  src: string;
  name: string;
  className?: string;
}) => {
  const [imageIsReady, setImageIsReady] = useState(false);

  useEffect(() => {
    const img = new (window as any).Image();
    img.src = src;
    img.onload = () => setImageIsReady(true);
  }, [src]);

  return (
    <div
      style={imageIsReady ? { background: "transparent" } : {}}
      className={`mmmAceMicrosite-img-placeholder ${className}`}
    >
      {imageIsReady ? <img src={src} alt={`product ${name}`} /> : ""}
    </div>
  );
};

export default Image;
