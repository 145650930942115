import data from "../data/data";

interface IContent {
  steps: Object[];
  products: any;
}

const cloneObject = (obj: {}) => JSON.parse(JSON.stringify(obj));

const next = (parent: string) => {
  const cData = cloneObject(data);

  const {
    content: { steps, products },
  }: { content: IContent } = cData;

  let result: any = steps.find(
    (step: any) =>
      step.items.filter((item: any) => item.parent === parent).length > 0
  );

  if (result) {
    result.items = result.items.filter((item: any) => item.parent === parent);
  } else {
    const items = products[parent];
    if (items) {
      const { length } = items;
      result = {
        items,
        question:
          length > 0
            ? `We recommend ${length} product${length > 1 ? "s" : ""}`
            : "There are no product recommendations for the selected options",
        step: 99,
      };
    }
  }

  return result;
};

export default next;
