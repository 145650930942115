import React from "react";
import { styles } from "../../data/step-style";
import RoundStepItem from "../RoundStepItem";
import IconStepItem from "../IconStepItem";
import ImageStepItem from "../ImageStepItem";

const StepStyled = ({
  id,
  name,
  style,
}: {
  id: string;
  name: string;
  style: string;
}) => {
  switch (style) {
    case styles.round:
      return <RoundStepItem itemKey={id} name={name} />;

    case styles.image:
      return <ImageStepItem itemKey={id} name={name} />;

    case styles.icon:
      return <IconStepItem itemKey={id} name={name} />;

    default:
      return null;
  }
};

export default StepStyled;
