import React from "react";

const images = [
  [process.env.MEDIA_URL + "/media/images/hero/banner-414x221.jpg", "414w"],
  [process.env.MEDIA_URL + "/media/images/hero/banner-768x200.jpg", "768w"],
  [process.env.MEDIA_URL + "/media/images/hero/banner-1440x336.jpg", "1440w"],
  [process.env.MEDIA_URL + "/media/images/hero/banner-2560x672.jpg", "2560w"],
  [process.env.MEDIA_URL + "/media/images/hero/banner-3840x1080.jpg", "3840w"],
];

const HeroImage = () => (
  <div className="hero-image" role="navigation">
    <picture className="hero-image__image">
      <source
        srcSet={images.map((img: string[]) => img.join(" ")).join(", ")}
        sizes="(max-width: 1000px) 70vw"
        media="(min-width: 768px)"
      />

      <img
        src={images[0][0]}
        alt="Ace Brand. Wrap it. Stick it. Brace it. Go."
        width="100%"
        height="100%"
        style={{ objectFit: "cover" }}
      />
    </picture>
  </div>
);

export default HeroImage;
