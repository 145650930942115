import next from "./Product-tree";

const multipleSelectionAnchor = "NO-MULTIPLE-SELECTION-ALLOWED";

const updateUrl = ({ url }: { url: string }) => {
  window.history.pushState(null, "", url);
};

const getUrl = (): string => window.location.href;

const haveHistory = (url: string = getUrl()): boolean => url.includes("#");

const clearHistory = ({
  step,
  key,
  url = getUrl(),
}: {
  step: number;
  key: string;
  url?: string;
}) => {
  if (haveHistory(url)) {
    url = url.substr(url.indexOf("#"));
    url = url.replace(`${step.toString()}/${key}/`.toLowerCase(), "");
    url = step === 0 ? " " : url;

    updateUrl({ url });

    return url;
  }
};

const setHistory = ({
  step,
  key,
  url = getUrl(),
  allowMultipleSelection,
}: {
  step: number;
  key: string;
  url?: string;
  allowMultipleSelection?: boolean;
}) => {
  if (!step || !key) return;

  key = key.toLowerCase();

  if (url.includes(`${step}/${key}/`)) return;

  if (step === 0 || !haveHistory(url)) {
    url = `#/${step}/${key}/`;
  } else {
    url = url.substr(url.indexOf("#"));

    if (!allowMultipleSelection) {
      url = url.includes(step.toString())
        ? url.substr(0, url.indexOf(step.toString()))
        : url;
    }

    url += `${step}/${key}/`;
  }

  updateUrl({ url });

  return url;
};

const reloadData = (url: string = getUrl()) => {
  const arr = url.substr(url.indexOf("#") + 1).split("/");

  let data: any[] = [];
  let step: number = -1;
  let selectedKeys: string[][] = [];

  data[1] = next("");

  for (let i = 0; i < arr.length; i++) {
    if (Number(arr[i])) {
      const key: string = arr[i + 1].toUpperCase();
      const mStep: number = parseInt(arr[i]);
      const newNext = next(key);

      if (!newNext) {
        clearHistory({ step: mStep, key });
        break;
      }

      selectedKeys[mStep] = selectedKeys[mStep]
        ? [...selectedKeys[mStep], key]
        : [key];

      if (newNext.step === 99 && data[newNext.step]) {
        const items = [...data[newNext.step].items, ...newNext.items].filter(
          (obj, pos, arr) =>
            arr.map((mapObj) => mapObj.name).indexOf(obj.name) !== pos
        );
        const { length } = items;
        data[newNext.step] = {
          items,
          step: 99,
          question:
            length > 0
              ? `We recommend ${length} product${length > 1 ? "s" : ""}`
              : `There are no product recommendations for the selected options`,
          allowMultipleSelection: false,
        };
      } else {
        data[newNext.step] = {
          ...newNext,
          allowMultipleSelection: newNext.question.includes(
            multipleSelectionAnchor
          ),
        };
      }

      if (i >= arr.length - 3) {
        step = newNext.step;
      }
    }
  }

  return { step, data, selectedKeys };
};

export { updateUrl, haveHistory, clearHistory, setHistory, reloadData };
