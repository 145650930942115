import React from "react";
import data from "./../../data/page";

const Header = () => {
  const { header } = data;

  return (
    <header id="0" data-testid="0" className="mmmAceMicrosite-header">
      <a href="#main" className="skip-link">
        Skip to content
      </a>
      <div>
        <h1>
          <a
            id="mmmAceMicrosite-walmartLink"
            href={header.walmartLink}
            rel="external"
          >
            <picture>
              <source
                media="(min-width: 992px)"
                srcSet={header.walmartDesktopLogo}
              />

              <img
                id="mmmAceMicrosite-walmartLogo"
                className="mmmAceMicrosite-logo-walmart"
                src={header.walmartLogo}
                alt="walmart logo"
              />
            </picture>
          </a>
        </h1>
      </div>
      <div>
        <img
          className="mmmAceMicrosite-logo-3m"
          src={header.mmmLogo}
          alt="3m logo"
        />
      </div>
    </header>
  );
};

export default Header;
